import axios, { AxiosResponse } from 'axios'
import config from '../config'

const BACKEND_HOST = config.BACKEND_HOST

export function transfrom(formData: FormData): Promise<AxiosResponse> {
  return axios.post(`${BACKEND_HOST}/transform`, formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
    responseType: 'blob',
  })
}
