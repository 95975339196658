import { Col, Radio, Row } from 'antd'
import Text from 'antd/lib/typography/Text'
import './ControlPanel.css'

interface ControlPanelProps {
  eyeLevel: number
  eyeLevelHandler: any
  saturation: number
  saturationHandler: any
}
function ControlPanel(props: ControlPanelProps) {
  return (
    <Row justify="center" style={{ padding: '60px 0px 0px 00px' }}>
      <Col>
        <Row>
          <Col>
            <Row>
              <Text>ระดับสายตา</Text>
            </Row>
            <Row>
              <Radio.Group
                onChange={props.eyeLevelHandler}
                value={props.eyeLevel}
              >
                <Radio value={0}>20/20</Radio>
                <Radio value={1}>20/25</Radio>
                <Radio value={2}>20/30</Radio>
                <Radio value={3}>20/40</Radio>
                <Radio value={4}>20/50</Radio>
                <Radio value={5}>20/70</Radio>
                <Radio value={6}>20/100</Radio>
              </Radio.Group>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col>
            <Row>
              <Text>ระดับความอิ่มสี</Text>
            </Row>
            <Row>
              <Radio.Group
                onChange={props.saturationHandler}
                value={props.saturation}
              >
                <Radio value={100} style={{ color: 'black' }}>
                  100
                </Radio>
                <Radio value={75}>75</Radio>
                <Radio value={50}>50</Radio>
                <Radio value={25}>25</Radio>
                <Radio value={0}>0</Radio>
              </Radio.Group>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ControlPanel
