import {
  LeftOutlined,
  LoadingOutlined,
  RedoOutlined,
  RightOutlined,
} from '@ant-design/icons'
import { Col, Row, Spin } from 'antd'
import Text from 'antd/lib/typography/Text'

import { useEffect, useState } from 'react'
import { SRLWrapper } from 'simple-react-lightbox'
import * as transformService from '../../../../../services/transform'
import ControlPanel from '../ControlPanel'
import './ImagePreview.css'

interface ImagePreviewProps {
  file: File
  eyeLevel: number
  eyeLevelHandler: any
  saturation: number
  saturationHandler: any
  setState: any
}
const eyeLevelList = [
  '20/20',
  '20/25',
  '20/30',
  '20/40',
  '20/50',
  '20/70',
  '20/100',
]

function ImagePreview(props: ImagePreviewProps) {
  const [convertedImage, setConvertedImage] = useState('')
  const [originalImage, setOriginalImage] = useState('')
  const [convertedImageFileName, setConvertedImageFileName] = useState('')
  const [loading, setLoading] = useState(true)
  const [fetchError, setFetchError] = useState(false)
  const [firstClick, setFirstClick] = useState(false)
  const SRLOptions = {
    settings: {
      autoplaySpeed: 0,
    },
    caption: {},
    buttons: {
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
    },
    thumbnails: { showThumbnails: false },
    progressBar: {},
  }
  function createConvertedFileName(
    fileName: string,
    contentType: string,
    eyeLevel: number,
    saturation: number
  ) {
    return (
      (fileName.split('.')[0] || 'Untitled') +
      '-' +
      eyeLevelList[eyeLevel] +
      '-' +
      saturation
    )
  }
  async function asyncDownload(
    file: File,
    eyeLevel: number,
    saturation: number
  ) {
    try {
      let formData = new FormData()
      formData.append('file', file)
      formData.append('eyeLevel', eyeLevel.toString())
      formData.append('saturation', saturation.toString())
      const response = await transformService.transfrom(formData)
      console.log('HERE' + response)
      if (
        response.status === 200 &&
        response.headers['content-type'].includes('image')
      ) {
        const contentType = response.headers['content-type']
        const blob = new Blob([response.data], { type: contentType })
        setConvertedImage(URL.createObjectURL(blob))
        setConvertedImageFileName(
          createConvertedFileName(file.name, contentType, eyeLevel, saturation)
        )
        setLoading(false)
        setFetchError(false)
      } else {
        setConvertedImage('')
        setConvertedImageFileName('')
        setLoading(false)
        setFetchError(true)
      }
    } catch (e) {
      setConvertedImage('')
      setConvertedImageFileName('')
      setLoading(false)
      setFetchError(true)
    }
  }
  useEffect(() => {
    setOriginalImage(URL.createObjectURL(props.file))
  }, [])

  //   useEffect(() => {
  //     // setLoading(true)
  //     // asyncDownload(props.file, props.eyeLevel, props.saturation)
  //   }, [props.eyeLevel, props.saturation])
  return (
    <>
      <Row gutter={[16, 0]}>
        <Col span={firstClick ? 12 : 24}>
          <Text className="image-title">สายตาปกติ</Text>
          <SRLWrapper
            options={{
              ...SRLOptions,
              settings: {
                ...SRLOptions.settings,
                downloadedFileName: props.file.name.split('.')[0] || 'Untitled',
              },
            }}
          >
            <img
              style={{ cursor: 'pointer', maxWidth: '100%', height: 'auto' }}
              alt={props.file.name.split('.')[0]}
              src={originalImage}
            />
          </SRLWrapper>
        </Col>
        {firstClick ? (
          <Col span={12}>
            <Text className="image-title">สายตาเลือนราง</Text>
            {fetchError ? (
              <Row
                justify="center"
                align="middle"
                style={{ width: '100%', height: '100%' }}
              >
                <RedoOutlined
                  onClick={() => {
                    setLoading(true)
                    asyncDownload(props.file, props.eyeLevel, props.saturation)
                  }}
                  style={{ fontSize: 100 }}
                />
              </Row>
            ) : loading ? (
              <Row
                justify="center"
                align="middle"
                style={{ width: '100%', height: '100%' }}
              >
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 100, color: 'black' }}
                      spin
                    />
                  }
                />
              </Row>
            ) : (
              <SRLWrapper
                options={{
                  ...SRLOptions,
                  settings: {
                    ...SRLOptions.settings,
                    downloadedFileName: convertedImageFileName,
                  },
                }}
              >
                <img
                  style={{
                    cursor: 'pointer',
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                  alt={convertedImageFileName}
                  src={convertedImage}
                />
              </SRLWrapper>
            )}
          </Col>
        ) : null}
      </Row>
      <ControlPanel
        eyeLevel={props.eyeLevel}
        eyeLevelHandler={props.eyeLevelHandler}
        saturation={props.saturation}
        saturationHandler={props.saturationHandler}
      ></ControlPanel>
      <Row style={{ marginTop: '50px' }} justify="space-between">
        <button
          className="change-image-button-style"
          onClick={() => props.setState(1)}
        >
          <LeftOutlined />
          <Text style={{ fontSize: '18px' }}>เปลี่ยนรูปภาพ</Text>
        </button>
        <button
          className="change-image-button-style"
          onClick={() => {
            setLoading(true)
            asyncDownload(props.file, props.eyeLevel, props.saturation)
            setFirstClick(true)
          }}
        >
          <Text style={{ fontSize: '18px' }}>จำลอง</Text>
        </button>
      </Row>
    </>
  )
}
ImagePreview.defaultProps = {
  eyeLevel: 0,
  saturation: 100,
}
export default ImagePreview
