import { Col, Row, Typography } from "antd";
import TextCircle from "../TextCircle";
import "./Step.css"
interface StepProps {
    step: string
    title: string
    description: string
}

const { Text } = Typography;

function Step(props: StepProps) {
    return (
        <Col>
            <Row justify="center">
                <TextCircle radius="55px" text={props.step}></TextCircle>
            </Row>
            <Row justify="center" style={{ marginTop: "12px" }}>
                <Text className="title-step">{props.title}</Text>
            </Row>
            <Row justify="center" style={{ marginTop: "12px" }}>
                <Text className="title-description">{props.description}</Text>
            </Row>
        </Col>
    )
}

export default Step