import { Col, Row } from 'antd'
import logo from '../../../resources/logo.png'
import { Typography } from 'antd'
import './Header.css'
import { Link } from 'react-router-dom'
const { Text } = Typography

function Header() {
  return (
    <>
      <Row className="header-style">
        <Col span={12}>
          <Row justify="start">
            <Col className="logo-style">
              <img src={logo} alt="logo" width="51" height="75" />
            </Col>
            <Col className="title-component">
              <Row>
                <Text className="title-thai">สำนักงานการวิจัยแห่งชาติ</Text>
              </Row>
              <Row>
                <Text className="title-eng">
                  National Research Council of Thailand
                </Text>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="end" gutter={[24, 0]} style={{ marginTop: '50px' }}>
            <Col>
              <Row>
                <Link to="/" onClick={() => window.location.reload()}>
                  <Text className="nav-menu">หน้าแรก</Text>
                </Link>
              </Row>
            </Col>
            {/* <Col>
                            <Row>
                                <Link to="/about" onClick={() => window.location.reload()}>
                                    <Text className="nav-menu">เกี่ยวกับ</Text>
                                </Link>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Link to="/contact" onClick={() => window.location.reload()}>
                                    <Text className="nav-menu">ติดต่อ</Text>
                                </Link>
                            </Row>
                        </Col> */}
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default Header
