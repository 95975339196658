import { Row } from 'antd'
import { Route, Switch, Redirect } from 'react-router-dom'
import Main from '../../pages/Main'
import './Content.css'
const pagePath = [
  {
    path: '/',
    component: Main,
  },
  {
    path: '*',
    component: () => <Redirect from="*" to="/"></Redirect>,
  },
]

function Content() {
  return (
    <Row>
      <Switch>
        {pagePath.map((value) => (
          <Route exact path={value.path}>
            {
              <>
                <div className="container">
                  <value.component />
                </div>
              </>
            }
          </Route>
        ))}
      </Switch>
    </Row>
  )
}

export default Content
