import { BrowserRouter as Router } from 'react-router-dom'
import Content from '../Content'
import Footer from '../Footer'
import Header from '../Header'
import './App.css'

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Content />
        <Footer />
      </Router>
    </div>
  )
}

export default App
