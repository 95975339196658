import "./TextCircle.css"

interface TextCircleProps {
    text: any
    radius: string
}

function TextCircle(props: TextCircleProps) {
    return (
        <div style={{ width: props.radius, height: props.radius }} className="circle">
            <p className="text">{props.text}</p>
        </div>
    )
}

export default TextCircle;