import { ChangeEventHandler } from 'react'
import './UploadButton.css'
interface UploadButtonProps {
  radius?: string
  width?: string
  height?: string
  text: string
  onClick: ChangeEventHandler<HTMLInputElement>
}
function UploadButton(props: UploadButtonProps) {
  return (
    <>
      <label
        className="upload-button-style"
        style={{
          borderRadius: props.radius,
          width: props.width,
          height: props.height,
        }}
      >
        <p className="text">{props.text}</p>
        <input
          type="file"
          name="file"
          accept="image/jpeg, image/jpg, image/png"
          onChange={props.onClick}
        />
      </label>
    </>
  )
}

export default UploadButton
