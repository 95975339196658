import { Col, List, Row, Typography } from 'antd'
import { Link } from 'react-router-dom'
import './Footer.css'

const { Text } = Typography

const data = [
  { title: 'หน้าแรก', path: '/' },
  // { title: "เกี่ยวกับเรา", path: "/about" },
]

function Footer() {
  return (
    <Col className="footer-container" span={24}>
      <Row className="footer-style">
        <Col className="first-col" span={10}>
          <Row>
            <Text className="title-nrct">สำนักงานการวิจัยแห่งชาติ (วช.)</Text>
          </Row>
          <Row>
            <Text className="description-nrct">
              กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม
            </Text>
          </Row>
        </Col>
        <Col className="second-col" span={6}>
          <Row>
            <Text className="title-col">ติดต่อเรา</Text>
          </Row>
          <Row>
            <Text className="description-contact">
              <b>ที่อยู่ : </b>เลขที่ 196 ถนนพหลโยธิน แขวงลาดยาว เขตจตุจักร กทม.
              10900
            </Text>
          </Row>
          <br></br>
          <Row>
            <Text className="description-contact">
              <b>เบอร์โทร : </b>025791370 - 9
            </Text>
          </Row>

          <Row>
            <Text className="description-contact">
              <b>Email : </b>pr@nrct.go.th
            </Text>
          </Row>
        </Col>
        <Col className="third-col" span={8}>
          <Row style={{ width: 'auto', display: 'grid' }}>
            <Text className="title-col">ข้อมูลเพิ่มเติม</Text>
          </Row>
          <List
            footer={<div></div>}
            bordered={false}
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                <Link to={item.path} onClick={() => window.location.reload()}>
                  <Text className="description-col">{item.title}</Text>
                </Link>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row align="middle" justify="start" style={{ height: '63px' }}>
        <Text className="description-col">
          © Copyright 2021. All Rights Reserved.
        </Text>
      </Row>
    </Col>
  )
}
export default Footer
