import { Col, RadioChangeEvent, Row } from 'antd'
import HowTo from '../../commons/HowTo'
import { Typography } from 'antd'
import './Main.css'
import { ChangeEvent, useState } from 'react'
import SelectFile from './components/SelectFile'
import SummaryFile from './components/SummaryFile'
import ImagePreview from './components/ImagePreview'

const { Text } = Typography

function Main() {
  const [state, setState] = useState(1)
  const [selectedFile, setSelectedFile] = useState<Array<File>>([])
  const [eyeLevel, setEyeLevel] = useState<number>(0)
  const [saturation, setSaturation] = useState<number>(100)
  function eyeLevelHandler(e: RadioChangeEvent) {
    setEyeLevel(e.target.value)
  }
  function saturationHandler(e: RadioChangeEvent) {
    setSaturation(e.target.value)
  }
  //   function deleteFile(value: File) {
  //     const files = selectedFile
  //     const arr = files.filter(function (item) {
  //       return item !== value
  //     })
  //     setSelectedFile(arr)
  //   }
  function uploadFileHandler(event: ChangeEvent<HTMLInputElement>) {
    if (event.target?.files) {
      const files = [event.target.files[0]]
      setSelectedFile(files)
      setEyeLevel(0)
      setSaturation(100)
      setState(2)
    }
  }
  //   function addFileHandler(event: ChangeEvent<HTMLInputElement>) {
  //     if (event.target?.files) {
  //       const file = event.target.files[0]
  //       let found = 0
  //       for (let i of selectedFile) {
  //         if (i.name === file.name) {
  //           found = 1
  //           break
  //         }
  //       }
  //       if (!found) {
  //         setSelectedFile([...selectedFile, file])
  //         setState(2)
  //       }
  //     }
  //   }
  //   function submitFileHandler() {
  //     setState(3)
  //   }
  //   function resetFunction() {
  //     setState(1)
  //   }
  function renderContent(state: number) {
    switch (state) {
      case 1:
        return <SelectFile onClick={uploadFileHandler} />
      case 2:
        return (
          <ImagePreview
            file={selectedFile[0]}
            eyeLevel={eyeLevel}
            eyeLevelHandler={eyeLevelHandler}
            saturation={saturation}
            saturationHandler={saturationHandler}
            setState={setState}
          ></ImagePreview>
        )

      //   <SummaryFile
      //     state="2"
      //     files={selectedFile}
      //     addFileFn={addFileHandler}
      //     deleteFn={deleteFile}
      //     submitFn={submitFileHandler}
      //     resetFn={resetFunction}
      //   />

      //   case 3:
      //     return (
      //       <SummaryFile
      //         state="3"
      //         files={selectedFile}
      //         addFileFn={addFileHandler}
      //         deleteFn={deleteFile}
      //         submitFn={submitFileHandler}
      //         resetFn={resetFunction}
      //       />
      //     )
      default:
        return <SelectFile onClick={uploadFileHandler} />
    }
  }

  return (
    <>
      <Col className="main-container" span={24}>
        <Row justify="center">
          <Text className="main-title">
            การจำลองการมองเห็น<br></br>สำหรับผู้มีสายตาเลือนราง
          </Text>
        </Row>
        <Row
          style={{
            width: '100%',
            display: 'grid',
            marginTop: '65px',
          }}
        >
          {renderContent(state)}
        </Row>
      </Col>
      {state === 1 ? <HowTo /> : null}
    </>
  )
}

export default Main
