import { Col, Row, Typography } from 'antd'
import Step from '../Step'
import './HowTo.css'

const { Text } = Typography

function HowTo() {
  return (
    <Row className="how-to-container" justify="center">
      <Row>
        <Col span={24} style={{ marginTop: '50px' }}>
          <Text className="title-how-to">วิธีการจำลองรูปภาพ</Text>
        </Col>
      </Row>
      <Row
        justify="center"
        gutter={[24, 0]}
        style={{ width: '100%', marginTop: '30px', marginBottom: '73px' }}
      >
        <Col span={8}>
          <Step
            title="อัพโหลดไฟล์รูปภาพ"
            description="เลือกไฟล์จากคอมพิวเตอร์ โดยไฟล์รูปจะต้อง
เป็น jpg หรือ png เท่านั้น"
            step="1"
          ></Step>
        </Col>
        <Col span={8}>
          <Step
            title="เลือกตัวเลือก"
            description="เมื่อเลือกตัวเลือกเสร็จสิ้น ให้กดจำลองรูปภาพ จากนั้นรอสักครู่เพื่อดูผลลัพธ์"
            step="2"
          ></Step>
        </Col>
        <Col span={8}>
          <Step
            title="ดูภาพขนาดใหญ่หรือดาวน์โหลดไฟล์ผลลัพธ์"
            description="เมื่อจำลองรูปภาพสำเร็จ คุณสามารถขยายรูปภาพได้โดยการกดคลิกที่รูปภาพ และคุณยังสามารถดาวน์โหลดไฟล์ภาพผลลัพธ์ได้ที่มุมขวาบนหลังจากคลิกที่รูปภาพแล้ว"
            step="3"
          ></Step>
        </Col>
      </Row>
    </Row>
  )
}

export default HowTo
