import { Row, } from "antd"
import { ChangeEventHandler } from "react"
import UploadButton from "../../../../commons/UploadButton"


interface SelectFileProps {
    onClick: ChangeEventHandler<HTMLInputElement>
}

function SelectFile(props: SelectFileProps) {

    return (
        <Row justify="center">
            <UploadButton text="เลือกไฟล์" width="200px" height="200px" radius="50%" onClick={props.onClick}></UploadButton>
        </Row>
    )
}

export default SelectFile